import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import styled from 'styled-components';

import { cardBackround } from '@/styles/shared';

export const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  a {
    width: 100%;
    height: 100%;
    max-width: calc(100vw / 3 - 40px);
  }

  img {
    height: 100%;
    width: 100%;
    max-width: calc(100vw / 3 - 40px);
    aspect-ratio: 1/1;
    object-fit: cover;
    transition: all 0.3s ease;

    @media ${({ theme }) => theme.media.desktop} {
      max-height: 100px;
    }
  }

  img:hover {
    transform: scale(1.1);
  }
`;

export const SingleImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 5px;

  &:hover {
    .overlay {
      opacity: 0.5;
    }

    .icon {
      opacity: 1;
    }
  }
`;

export const Overlay = styled.div<{ className: string }>`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.primary};
  opacity: 0;
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
`;

export const Icon = styled(Instagram)`
  color: white;
  width: 40%;
  height: 40%;
  opacity: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

export const Wrapper = styled.div`
  padding: 30px;
  ${cardBackround};

  @media ${({ theme }) => theme.media.desktop} {
    max-width: 400px;
  }

  h2 {
    font-size: 20px;

    @media ${({ theme }) => theme.media.tablet} {
      font-size: 24px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0 0;
`;
