import type { ReactNode } from 'react';
import styled from 'styled-components';

import { button } from '@/styles/shared';

export const StyledLinkWrapper = styled.div`
  a {
    ${button};
    text-align: center;
    font-size: 14px;

    @media ${({ theme }) => theme.media.tablet} {
      font-size: 16px;
      text-align: unset;
    }
  }
`;

export const StyledLink = styled.a<{
  children: ReactNode;
  href: string;
  rel: string;
  target: string;
}>`
  ${button};
  text-align: center;
  font-size: 14px;

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 16px;
    text-align: unset;
  }
`;
